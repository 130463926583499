import { Injectable, OnDestroy } from '@angular/core';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { fetchAuthSession } from "aws-amplify/auth";

@Injectable({
  providedIn: 'root'
})
export class MomWebSocketService implements OnDestroy {

  private websocketRetries = 0;
  private subject: WebSocketSubject<unknown> | null = null;
  private isReconnecting = false; // New flag to prevent multiple reconnections
  public isPrimaryWebsocketConnected = new BehaviorSubject<boolean>(false);
  public isFailoverWebsocketConnected = new BehaviorSubject<boolean>(false);
  public messageStream = new Subject<any>();
  private readonly maxRetryDelay = 30000; // Maximum delay of 30 seconds

  constructor() {
    this.connectWebSocket();
  }

  getPrimaryWebsocketUrl(): string {
    let apiOption = localStorage.getItem('apiOption');

    if (apiOption == 'PRIMARY') {
      return environment.primaryWebsocketUrl;
    }
    else if (apiOption == 'FAILOVER') {
      return environment.secondaryWebsocketUrl;
    }
    else {
      return environment.primaryWebsocketUrl;
    }
  }

  getSecondaryWebsocketUrl(): string {
    let apiOption = localStorage.getItem('apiOption');

    if (apiOption == 'PRIMARY') {
      return environment.primaryWebsocketUrl;
    }
    else if (apiOption == 'FAILOVER') {
      return environment.secondaryWebsocketUrl;
    }
    else {
      return environment.secondaryWebsocketUrl;
    }
  }

  private async connectWebSocket() {
    if (this.isReconnecting) return; // Prevent reentry if already reconnecting

    let url;
    if (this.websocketRetries > 6) {
      this.websocketRetries = 0; // Reset retries after 6 attempts
      url = this.getPrimaryWebsocketUrl();
    } else if (this.websocketRetries > 3) {
      url = this.getSecondaryWebsocketUrl();
    } else {
      url = this.getPrimaryWebsocketUrl();
    }

    var cognitoTokens = (await fetchAuthSession()).tokens;
    let idToken = cognitoTokens?.idToken?.toString();

    this.subject = webSocket({
      url: url,
      protocol: ['token', idToken ? idToken : ''], //this is how we have to add our cognito token to the connection and our custom AWS authorizer will validate it
      openObserver: {
        next: () => {
          console.log('WebSocket connected');

          if (url === environment.primaryWebsocketUrl) {
            this.isPrimaryWebsocketConnected.next(true);
          } else {
            this.isFailoverWebsocketConnected.next(true);
          }
          
          this.websocketRetries = 0; // Reset retry count on successful connection
          this.isReconnecting = false; // Reset the reconnection flag
        }
      },
      closeObserver: {
        next: () => {
          console.log('WebSocket closed');
          this.isPrimaryWebsocketConnected.next(false);
          this.isFailoverWebsocketConnected.next(false);
          this.retryConnection();
        }
      }
    });

    this.subject.subscribe({
      next: (msg) => this.messageStream.next(msg),
      error: (err) => {
        //1001 code is "Going Away" and is expected when the server closes the connection
        //AWS websocket API has an idle timeout of 10 minutes, so this is expected and we don't need to log an error in this case
        if ((err?.code ?? 0) !== 1001) {  // Only log errors that aren't "Going Away"
            console.error('WebSocket error:', err);
            console.log(err?.code);
            console.log(err?.reason);
            console.log(err?.message);
          }
        this.retryConnection();
      },
      complete: () => {
        console.log('WebSocket complete');
        this.retryConnection();
      }
    });
  }

  private retryConnection() {
    if (this.isReconnecting) return; // Prevent multiple reconnection attempts

    this.isReconnecting = true;
    if (this.subject) {
      this.subject.complete(); // Close the WebSocket
      this.subject = null;     // Nullify the subject to avoid memory leaks
    }
    
    this.websocketRetries++;
    const delay = Math.min(1000 * this.websocketRetries, this.maxRetryDelay);
    console.log(`Reconnecting in ${delay / 1000} seconds...`);
    
    setTimeout(() => {
      this.isReconnecting = false;
      this.connectWebSocket();
    }, delay);
  }

  ngOnDestroy() {
    this.websocketRetries = 0;
    if (this.subject) {
      this.subject.complete();
      this.subject = null; // Ensure resources are fully released on destroy
    }
    this.isReconnecting = false;
  }
}
