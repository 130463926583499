import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile } from '../models/user-profile';
import { MomApiService } from './mom-api.service';
import { MessageService } from 'primeng/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { Me } from '../models/me';

@Injectable({
  providedIn: 'root' // Ensures this service is a singleton throughout the app
})
export class UserService {
  // BehaviorSubject to store and emit the current user profile
  public userProfileSubject = new BehaviorSubject<Me | null>(null);
  public kochIdFirstName = new BehaviorSubject<string | null>(null);
  public kochIdLastName = new BehaviorSubject<string | null>(null);
  public kochIdEmail = new BehaviorSubject<string | null>(null);

  loading: string[] = [];

  constructor(private momApiService: MomApiService, private messageService: MessageService) {
    this.loadUserProfile();
    this.loadKochIdInfo();
  }

  // Method to set user profile information
  setUserProfile(profile: Me): void {
    this.userProfileSubject.next(profile);
  }

  // Method to get the current user profile as a snapshot (useful for synchronous needs)
  getUserProfile(): Me | null {
    return this.userProfileSubject.value;
  }

  // Method to clear user profile information (e.g., on logout)
  clearUserProfile(): void {
    this.userProfileSubject.next(null);
  }

  async loadKochIdInfo() {
    const userAttributes = await fetchUserAttributes();
    this.kochIdEmail.next(userAttributes.email!);
    this.kochIdFirstName.next(userAttributes.given_name!);
    this.kochIdLastName.next(userAttributes.family_name!);
  }

  loadUserProfile(): void {
    //API call to get this specific users profile information
    this.loading.push('userProfile');
    this.momApiService.getData('refdata/security/me')
      .subscribe({
        next: (result: any) => {
            this.userProfileSubject.next(result);
        },
        error: (error) => {
          
          this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
          this.loading = this.loading.filter(item => item !== 'userProfile');
        },
        complete: () => {
          
          this.loading = this.loading.filter(item => item !== 'userProfile');
        }
      });
  }
}
